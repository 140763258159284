import React, { useEffect } from "react"
import { useColorMode, jsx } from "theme-ui"
import { Parallax } from "react-spring/renderprops-addons.cjs"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Projects from "../components/projects"
import About from "../components/about"
import Contact from "../components/contact"

const Cara = () => {
  const [colorMode, setColorMode] = useColorMode();
  const handleTheme = () => {
    const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");

    let mode = colorMode;
    if (darkThemeMq.matches) {
      mode = `dark`;
    } else {
      mode = `light`;
    }

    if (mode != colorMode) {
      setColorMode(mode);
    }
  }

  useEffect(() => {
    handleTheme();
    setInterval(handleTheme, 1000);
  }, []);

  return (
    <Layout>
      <Parallax pages={5}>
        <Hero offset={0} factor={1} />
        <Projects offset={1} factor={2} />
        <About offset={3} factor={1} />
        <Contact offset={4} factor={1} />
      </Parallax>
    </Layout>
  );
}

export default Cara
