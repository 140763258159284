import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p><strong parentName="p">{`S4M Tech, Inc.`}</strong>{` is a global technology company, developing a next-generation VPN platform for mobile and desktop devices. `}<strong parentName="p">{`S4M Tech, Inc.`}</strong>{` combines proprietary technologies and its expertise in consumer VPN development to create the solutions that shape the future of digital privacy and digital security.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      