import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Welcome to S4M Tech, Inc!`}</h1>
    <p><strong parentName="p">{`S4M Tech, Inc.`}</strong>{` is a fast-growing SaaS business with a primary focus on digital privacy and digital security.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      