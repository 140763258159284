/** @jsx jsx */
import { Box, jsx } from "theme-ui"

const Footer = () => (
  <Box as="footer" variant="footer">
    Copyright &copy; {new Date().getFullYear()} S4M Tech, Inc. All rights reserved.
    <br />
    8 The Green #6302, Dover, DE 19901
  </Box>
)

export default Footer
