import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`What We Do`}</h2>
    <ProjectCard title="Advertising" link="https://ads.seed4.me" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  We represent the new era of advertising that respects consumers’ privacy. Grow your business with <strong>S4M Tech Ads</strong>.
    </ProjectCard>
    <ProjectCard title="VPN" link="https://seed4.me/pages/pricing" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Keep your personal information private with <strong>Seed4.Me VPN</strong>. <strong>S4M Tech, Inc.</strong> is the company behind <strong>Seed4.Me VPN</strong> brand. We offer fast and affordable VPN for Windows, Mac, iOS, Android, Android TV, and Amazon Kindle. One subscription is valid for all devices
    </ProjectCard>
    <ProjectCard title="Advisory & Infrastructure" link="mailto:info@s4m.tech" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Find insights, advice, and tools with our expertise in building VPN and subscription-based services.
    </ProjectCard>
    <ProjectCard title="VPN Affiliate Program" link="https://affiliate.seed4.me" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  Get paid to promote the world's leading VPN provider.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      